import FindADog from "../../../Assets/images/find.jpg";

const Questions = [
  // {
  //   name: "welcome-screen",
  //   id: "jg1401r",
  //   attributes: {
  //     label: "Find a Dog!",
  //     description: "You're just few steps away from picking your best friend",
  //     attachment: {
  //       type: "image",
  //       url: FindADog
  //     }
  //   }
  // },
  {
    name: "multiple-choice",
    id: "8",
    attributes: {
      required: true,
      label: "What is your preferred fur length?",
      choices: [
        {
          label: "Short",
          value: "Short",
        },
        {
          label: "Medium",
          value: "Medium",
        },
        {
          label: "Long",
          value: "Long",
        },
        {
          label: "No preference",
          value: "No preference",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "9",
    attributes: {
      required: true,

      label: "How much coat shedding can you deal with?",
      choices: [
        {
          label: "Shedding doesn't bother me",
          value: "Shedding doesn't bother me",
        },
        {
          label: "Average shedding is fine",
          value: "Average shedding is fine",
        },
        {
          label: "As little as possible",
          value: "As little as possible",
        },
        {
          label: "No preference",
          value: "No preference",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "10",
    attributes: {
      required: true,
      label: "Do you have a preferred coat color?",
      choices: [
        {
          label: "Black",
          value: "Black",
        },
        {
          label: "White",
          value: "White",
        },
        {
          label: "Brown",
          value: "Brown",
        },
        {
          label: "No preference",
          value: "No preference",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "11",
    attributes: {
      required: true,
      label: "How trainable do you want your dog to be?",
      choices: [
        {
          label: "Easily trainable",
          value: "Easily trainable",
        },
        {
          label: "Trainable",
          value: "Trainable",
        },
        {
          label: "Difficult to train",
          value: "Difficult to train",
        },
        {
          label: "No preference",
          value: "No preference",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "12",
    attributes: {
      required: true,

      label:
        "How much time do you have on a normal day for training and exercises with your dog?",
      choices: [
        {
          label: "0-30 minutes",
          value: "0-30 minutes",
        },
        {
          label: "30-60 minutes",
          value: "30-60 minutes",
        },
        {
          label: "1-2 hours",
          value: "1-2 hours",
        },
        {
          label: "More than 2 hours",
          value: "More than 2 hours",
        },
        {
          label: "No preference",
          value: "No preference",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "13",
    attributes: {
      required: true,
      label: "How frequently can you care for your dog's grooming needs?",
      description: "Grooming needs of your dog includes: grooming the coat, dental care, trimming the nails, cleaning the ears, eye care, physical exercise, and health maintenance.",
      choices: [
        {
          label: "Monthly",
          value: "Monthly",
        },
        {
          label: "Twice a month",
          value: "Twice a month",
        },
        {
          label: "Every week",
          value: "Every week",
        },
        {
          label: "No preference",
          value: "No preference",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "14",
    attributes: {
      required: true,
      label: "As an adult, have you ever owned a dog or do you have any experience?",
      choices: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
        {
          label: "No Preference",
          value: "No Preference",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "15",
    attributes: {
      required: true,
      label: "Does anyone in your household shows allergic reaction to dogs?",
      choices: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "16",
    attributes: {
      required: true,
      label:
        "Do you want us to exclude breeds that are in Rasseliste in Germany?",
      description: 'Dogs on the German "Rasseliste" are considered potentially dangerous. There are special regulations for them, such as mandatory muzzle and leash requirements, holding permits, and other measures to increase public safety.',
      choices: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
        {
          label: "No preference",
          value: "No preference",
        },
      ],
    },
  },
];

export default Questions;
