import FindADog from "../../../Assets/images/find.jpg";

const Questions = [
  {
    name: "multiple-choice",
    id: "1",
    attributes: {
      required: true,
      label: "How big can/should the dog be(shoulder height)?",
      choices: [
        {
          label: "Small (up to 35cms)",
          value: "Small (up to 35cms)",
        },
        {
          label: "Medium (35-60cms)",
          value: "Medium (35-60cms)",
        },
        {
          label: "Large (over 60cms)",
          value: "Large (over 60cms)",
        },
        {
          label: "No preference",
          value: "No preference",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "2",
    attributes: {
      required: true,
      label: "How much time you will have for your dog on a normal day (for walk)?",
      choices: [
        {
          label: "20-30 mins",
          value: "20-30 mins",
        },
        {
          label: "30-60 mins",
          value: "30-60 mins",
        },
        {
          label: "60-90 mins",
          value: "60-90 mins",
        },
        {
          label: "90-120 mins",
          value: "90-120 mins",
        },
        {
          label: "No preference",
          value: "No preference",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "3",
    attributes: {
      required: true,
      label: "How much barking are you okay with?",
      choices: [
        {
          label: "as little as possible",
          value: "as little as possible",
        },
        {
          label: "some barking",
          value: "some barking",
        },
        {
          label: "Lots of barking",
          value: "Lots of barking",
        },
        {
          label: "No preference",
          value: "No preference",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "4",
    attributes: {
      required: true,
      label: "What is your home like?",
      choices: [
        {
          label: "Small Apartment",
          value: "Small Apartment",
        },
        {
          label: "Big Apartment",
          value: "Big Apartment",
        },
        {
          label: "House with small yard",
          value: "House with small yard",
        },
        {
          label: "House with big yard",
          value: "House with big yard",
        }
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "5",
    attributes: {
      required: true,
      label: "On a normal working day, how many hours would your dog be alone?",
      description: "Please note that we are not suggesting that you leave your dog alone for up to 6 hours. Leaving your dog alone for longer can lead to stress, anxiety and other problems. Your four-legged friend is happy to have company and attention! In specific cases, please ask the breeder or another person who can provide information.",
      choices: [
        {
          label: "0-3 hours",
          value: "0-3 hours",
        },
        {
          label: "3-6 hours",
          value: "3-6 hours",
        }
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "6",
    attributes: {
      required: true,
      label: "Do you have children aged 0-10 years in your house?",
      choices: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "7",
    attributes: {
      required: true,
      label: "What’s your monthly budget/allowance?",
      description: "While planning your monthly budget for your furry friend, don't forget to consider additional costs like training, pet insurance, and occasional boarding or pet sitting. These expenses can be added to the regular costs of food, grooming, and vet care to ensure your dog stays happy, healthy, and well-behaved!",
      choices: [
        {
          label: "25-100€",
          value: "25-100€",
        },
        {
          label: "100-200€",
          value: "100-200€",
        },
        {
          label: "200€ +",
          value: "200€ +",
        }
      ],
    },
  }
];

export default Questions;
