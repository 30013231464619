import React, { useEffect } from "react";
import "./footer.scss";
import { useNavigate } from "react-router";
import web from "../../Assets/images/domain.png";
import linkdin from "../../Assets/images/li.png";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="test-cokkie">
        <CookieConsent
          location="bottom"
          buttonText={t("cookie_btn")}
          cookieName="cookiess"
          style={{ background: "#e44142" }}
          buttonStyle={{
            background: "#fffff",
            color: "#023347",
            fontSize: "18px",
            borderRadius: "8px",
            marginRight: "50px",
            width: "98px",
          }}
          expires={365}
        >
          {t("cookie_text")}
        </CookieConsent>
      </div>
      <div className="footer">
        <Container className="footer-inner">
          <div className="footer-title">
            <h5>FindaDog</h5>
          </div>
          <div className="social_media">
            <ul>
              <li>
                <a
                  href="https://www.bxl.digital"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={web} alt="Website" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/beyond-x-labs-gmbh"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkdin} alt="LinkedIn" />
                </a>
              </li>
            </ul>
            <div className="footer_links">
              <a
                className="footer-page"
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                {t("privacy_policy")}
              </a>
              <a
                className="footer-page"
                onClick={() => {
                  navigate("/impressum");
                }}
              >
                {t("imprint")}
              </a>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
