import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import "./results.scss";
import { withTranslation } from "react-i18next";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";
import up from "../../../Assets/images/up.png";
import up1 from "../../../Assets/images/up1.png";
import down from "../../../Assets/images/down.png";
import down1 from "../../../Assets/images/down1.png";

const Recommendations = ({
  item,
  match,
  t,
  onChildClick,
  selectedItems,
  onDislikeClick,
  dislikedItems,
  resetItems,
}) => {
  const { i18n } = useTranslation();

  let groom = item && item.grooming ? 5 - parseInt(item.grooming) : 0;
  let shedding = item && item.shedding ? 5 - parseInt(item.shedding) : 0;

  const Sociability = Math.round(
    (((item && item.good_with_children
      ? parseInt(item.good_with_children)
      : 0) +
      (item && item.good_with_other_dogs
        ? parseInt(item.good_with_other_dogs)
        : 0) +
      (item && item.good_with_strangers
        ? parseInt(item.good_with_strangers)
        : 0)) /
      (5 * 3)) *
      100
  );

  const Grooming = Math.round(((groom + shedding) / (5 * 2)) * 100);
  const Trainability = Math.round(
    (((item && item.trainability ? parseInt(item.trainability) : 0) +
      (item && item.energy ? parseInt(item.energy) : 0)) /
      (5 * 2)) *
      100
  );
  const Alertness = Math.round(
    (((item && item.barking ? parseInt(item.barking) : 0) +
      (item && item.protectiveness ? parseInt(item.protectiveness) : 0)) /
      (5 * 2)) *
      100
  );

  function calculateAverageWeightKg() {
    const minWeight = parseInt(item?.min_weight_female);
    const maxWeight = parseInt(item?.max_weight_female);

    if (isNaN(minWeight) || isNaN(maxWeight)) {
      return t("nan_value");
    } else {
      return Math.round(((minWeight + maxWeight) / 2) * 0.453592);
    }
  }

  function Cms() {
    const minHeight = parseInt(item?.min_height_female);
    const maxHeight = parseInt(item?.max_height_female);

    if (isNaN(minHeight) || isNaN(maxHeight)) {
      return t("nan_value");
    } else {
      return Number((minHeight + maxHeight) / 2);
    }
  }

  const [isFlipped, setIsFlipped] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [isLikedClicked, setIsLikedClicked] = useState(false);
  const [isDislikedClicked, setIsDislikedClicked] = useState(false);

  // const handleCardClick = (value) => {
  //   const isSelected = selectedItems.includes(value);
  //   if (isSelected) {
  //     setIsClicked(!isClicked);
  //   } else {
  //     if (selectedItems?.length < 2) {
  //       setIsClicked(!isClicked);
  //     }
  //   }
  //   onChildClick(value);
  // };

  const handleLikeClick = (value) => {
    if (isDislikedClicked) {
      setIsDislikedClicked(false);
    }
    setIsLikedClicked(!isLikedClicked);
    onChildClick(value);
  };

  const handleDislikeClick = (value) => {
    if (isLikedClicked) {
      setIsLikedClicked(false);
    }
    setIsDislikedClicked(!isDislikedClicked);
    onDislikeClick(value);
  };

  const handleCardFlipped = (event) => {
    event.stopPropagation();
    setIsFlipped(!isFlipped);
  };

  const cardClassName = `flipping-card ${isFlipped ? "flipped" : ""}`;
  //const cardFrontClassName = `${isClicked ? 'clicked' : ''}`;
  const cardFrontClassName = `${
    // isClicked && selectedItems.length > 0 ? "clicked" : ""
    isLikedClicked && selectedItems.length > 0 ? "clicked" : ""
  }`;

  useEffect(() => {
    if (resetItems) {
      setIsLikedClicked(false);
      setIsDislikedClicked(false);
    }
  }, [resetItems]);

  // const [expand, setExpand] = useState(false);

  return (
    <>
      <p></p>
      <p></p>

      <div
        className="mob-card"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "15%",
          marginBottom: "25%",
        }}
      >
        <Card className={`results-card ${cardClassName}`}>
          <div
            className={`card-face front ${cardFrontClassName}`}
            // onClick={() => {
            //   handleCardClick(item?.name);
            // }}
            onClick={handleCardFlipped}
          >
            <div className="results-div">
              <img
                src={item?.image_link || item?.img}
                alt="dog"
                className="results-img"
              />
            </div>
            <CardContent className="dogContent">
              <div className="dogInfo">
                <Typography
                  style={{ color: "#2C291E", textAlign: "left" }}
                  className="dogInfo-inner"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <span className="dogName">
                    {i18n.language === "de" ? item?.name_de : item?.name}{" "}
                  </span>

                  <div className="info_percent">
                    <span
                      align="left"
                      className="more_info"
                      onClick={handleCardFlipped}
                    >
                      {/* {t("more_info")} */}
                    </span>

                    <button align="right" className="dogMatch">
                      {t("match")} {match ? match : ""}
                    </button>
                  </div>
                </Typography>
              </div>

              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <Grid
                  className="result-card-bottom"
                  item
                  xs={4}
                  style={{
                    borderRight: "1px solid #fff",
                    borderBottomLeftRadius: "8px",
                  }}
                >
                  <h3>{match?.popularity_rank}</h3>
                  <p>{t("rank")}</p>
                </Grid> */}
                <Grid
                  className="result-card-bottom right-side"
                  item
                  xs={6}
                  style={{ borderRight: "1px solid #fff" }}
                >
                  <h3>
                    {/* {Number(
                      (parseInt(item?.min_height_female * 2.54) +
                        parseInt(item?.max_height_female * 2.54)) /
                        2
                    )} */}
                    {Cms()} {t("cms")}
                  </h3>
                  <p>{t("average_height")}</p>
                </Grid>
                <Grid
                  className="result-card-bottom"
                  item
                  xs={6}
                  style={{ borderBottomRightRadius: "8px" }}
                >
                  {/* <h3>{Number((parseInt(item?.min_weight_female) + parseInt(item?.max_weight_female)) / 2)} {t('lbs')}</h3> */}
                  <h3>
                    {/* {Math.round(
                      ((parseInt(item?.min_weight_female) +
                        parseInt(item?.max_weight_female)) /
                        2) *
                        0.453592
                    )} */}
                    {calculateAverageWeightKg()} {t("kgs")}
                  </h3>

                  <p>{t("average_weight")}</p>
                </Grid>
              </Grid>
            </CardContent>
          </div>

          <div className="card-face back" onClick={handleCardFlipped}>
            <div className="dogInfo-back">
              <p
                style={{
                  marginBottom: "15px",
                  marginTop: "0",
                  fontSize: "12px",
                }}
              >
                {match?.description}
              </p>

              {item &&
                item.colour &&
                item.colour !== null &&
                item.colour !== "Not available" && (
                  <Typography
                    variant="body2"
                    style={{ marginBottom: "5px", fontSize: "12px" }}
                  >
                    <span className="highlight-text">{t("colors")}:</span>{" "}
                    {i18n.language === "de" ? item.colour_de : item.colour}
                  </Typography>
                )}
              {item &&
                item.temperament &&
                item.temperament !== null &&
                item.temperament !== "Not available" && (
                  <Typography
                    variant="body2"
                    style={{ marginBottom: "5px", fontSize: "12px" }}
                  >
                    <span className="highlight-text">{t("temperament")}:</span>{" "}
                    {i18n.language === "de"
                      ? item.temperament_de
                      : item.temperament}
                    ;
                  </Typography>
                )}

              {Sociability > 0 && (
                <>
                  <div
                    className="progressBar-main"
                    style={{ marginTop: "10px" }}
                  >
                    <span className="progressBar-name">
                      <span className="highlight-text">
                        {t("sociability_friendliness")}:
                      </span>
                    </span>
                    <span className="progressBar">
                      <LinearProgress
                        variant="determinate"
                        value={Sociability}
                      />
                    </span>
                  </div>
                  <div className="combineName" style={{ display: "flex" }}>
                    <p
                      style={{
                        margin: "0",
                        textAlign: "start",
                        fontSize: "11px",
                      }}
                    >
                      {t("progressbar_text_1")}
                    </p>
                    <p
                      style={{
                        margin: "0",
                        textAlign: "end",
                        fontSize: "11px",
                      }}
                    >
                      {t("progressbar_text_2")}
                    </p>
                  </div>
                </>
              )}
              {Grooming > 0 && (
                <>
                  <div
                    className="progressBar-main"
                    style={{ marginTop: "10px" }}
                  >
                    <span className="progressBar-name">
                      <span className="highlight-text">
                        {t("grooming_ease")}:
                      </span>
                    </span>
                    <span className="progressBar">
                      <LinearProgress variant="determinate" value={Grooming} />
                    </span>
                  </div>
                  <div className="combineName" style={{ display: "flex" }}>
                    <p
                      style={{
                        margin: "0",
                        textAlign: "start",
                        fontSize: "11px",
                      }}
                    >
                      {t("progressbar_text_new1")}
                    </p>
                    <p
                      style={{
                        margin: "0",
                        textAlign: "end",
                        fontSize: "11px",
                      }}
                    >
                      {t("progressbar_text_new2")}
                    </p>
                  </div>
                </>
              )}
              {Trainability > 0 && (
                <>
                  <div
                    className="progressBar-main"
                    style={{ marginTop: "10px" }}
                  >
                    <span className="progressBar-name">
                      <span className="highlight-text">
                        {t("trainability")}:
                      </span>
                    </span>
                    <span className="progressBar">
                      <LinearProgress
                        variant="determinate"
                        value={Trainability}
                      />
                    </span>
                  </div>
                  <div className="combineName" style={{ display: "flex" }}>
                    <p
                      style={{
                        margin: "0",
                        textAlign: "start",
                        fontSize: "11px",
                      }}
                    >
                      {t("progressbar_text_new1")}
                    </p>
                    <p
                      style={{
                        margin: "0",
                        textAlign: "end",
                        fontSize: "11px",
                      }}
                    >
                      {t("progressbar_text_new2")}
                    </p>
                  </div>
                </>
              )}
              {Alertness > 0 && (
                <>
                  <div
                    className="progressBar-main"
                    style={{ marginTop: "10px" }}
                  >
                    <span className="progressBar-name">
                      <span className="highlight-text">{t("alertness")}:</span>
                    </span>
                    <span className="progressBar">
                      <LinearProgress variant="determinate" value={Alertness} />
                    </span>
                  </div>
                  <div className="combineName" style={{ display: "flex" }}>
                    <p
                      style={{
                        margin: "0",
                        textAlign: "start",
                        fontSize: "11px",
                      }}
                    >
                      {t("progressbar_text_1")}
                    </p>
                    <p
                      style={{
                        margin: "0",
                        textAlign: "end",
                        fontSize: "11px",
                      }}
                    >
                      {t("progressbar_text_2")}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="card_like">
            <div onClick={() => handleLikeClick(item?.name)}>
              {isLikedClicked ? (
                <img src={up1} alt="like" />
              ) : (
                <img src={up} alt="like" />
              )}
            </div>
            <div onClick={() => handleDislikeClick(item?.name)}>
              {isDislikedClicked ? (
                <img src={down1} alt="dislike" />
              ) : (
                <img src={down} alt="dislike" />
              )}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default withTranslation()(Recommendations);
