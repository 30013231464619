import React, { useEffect } from "react";
import "./HomePage.scss";
import homeImage from "../../../Assets/images/HomeImage.png";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const HomePage = (props) => {
  const { t } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="homepage">
      <div className="homepage-inner">
        <Container className="home-inner">
          <Grid container spacing={2}>
            <Grid item lg={6} className="box-1">
              <div className="box home-text">
                <p>
                  <b>{t("homepage_text1")}</b>
                  <br />
                  {t("homepage_text2")}
                </p>
                <Link to="/Qna" className="btn">
                  {t("homepage_button")}
                </Link>
              </div>
            </Grid>
            <Grid item lg={6} className="box-2">
              <div className="box">
                <div className="box-image">
                  <img src={homeImage} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default withTranslation()(HomePage);
