import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Button from "@mui/material/Button";
import Questions from "./QuestionsMore";
import QuestionsDe from "./QuestionsMoreDe";
import { useTranslation } from "react-i18next";
import "./Qna.scss";
import { withTranslation } from "react-i18next";

const ChoosePriority = ({ ans, handleResult }) => {
  const [checked, setChecked] = useState([]);
  const { t, i18n } = useTranslation();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length > 5) return;
    setChecked(newChecked);
  };

  return (
    <div style={{ textAlign: "center" }} className="choose-priority">
      <h3>{t("mark_quetions")}</h3>
      <List>
        {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((value, index) => {
          const labelId = `checkbox-list-label-${value}`; */}
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value, index) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem key={value} disablePadding>
              <ListItemButton onClick={handleToggle(value)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                  />
                </ListItemIcon>
                {Questions[index] &&
                  Questions[index].attributes &&
                  Questions[index].attributes.label && (
                    <ListItemText
                      id={labelId}
                      primary={
                        i18n.language === "de" ? (
                          <>
                            {QuestionsDe[index].attributes.label}
                            <span className="priority-ans">{ans[index]}</span>
                          </>
                        ) : (
                          <>
                            {Questions[index].attributes.label}
                            <span className="priority-ans">{ans[index]}</span>
                          </>
                        )
                      }
                    />
                  )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <div
        className="priority-btn"
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          background: "#fff",
          // position: "fixed",
          bottom: "0",
          paddingBottom: "10px",
          width: "100%",
          right: "40px",
        }}
      >
        <Button
          variant="contained"
          className="skip btn"
          onClick={() => handleResult(checked)}
        >
          {t("skip")}
        </Button>
        <Button
          variant="contained"
          className="continue pri-btn btn"
          color="primary"
          onClick={() => handleResult(checked)}
        >
          {t("continue")}
        </Button>
      </div>
    </div>
  );
};

export default withTranslation()(ChoosePriority);
