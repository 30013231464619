import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import QnA from "./Components/Modules/Q&A/QnA";
import UserFeedback from "./Components/Modules/ShowResults/UserFeedback";
import Header from "./Components/Global/Header";
import Footer from "./Components/Global/Footer";
// import Home from "./Components/Global/Home";
// import Loader from './Components/Global/Loader';
import Home from "./Components/Modules/Home/Home";
import Privacy_policy from "./Components/Modules/Privacy_policy/Privacy_policy";
import Impressum from "./Components/Modules/Impressum/Impressum";
import "./App.css";
import Loader from "./Components/Global/Loader";

const App = () => {

  const router = createBrowserRouter([
    {
      path: "/Qna",
      element: (
        <>
          <Header />
          <QnA />
        </>
      ),
    },
    {
      path: "/",
      element: (
        <>
          <Header />
          <Home />
          <Footer />
        </>
      ),
    },
    {
      path: "/test",
      element: (
        <>
          <Loader />
        </>
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <>
          <Header />
          <Privacy_policy />
          <Footer />,
        </>
      ),
    },
    {
      path: "/impressum",
      element: (
        <>
          <Header />
          <Impressum />
          <Footer />,
        </>
      ),
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
