import React, { useEffect } from "react";
import "./style.scss";

import { withTranslation } from "react-i18next";

const PrivacyPolicy = (props) => {
  const { t } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const textAlignStyle = { verticalAlign: "inherit" };

  return (
    <div className="privacy-page">
      <div id="jmdcontent" lang="de" data-jid="510835" data-jtype="policy">
        <h1 id="jsectiondatenschutzerklrung">Datenschutzerklärung</h1>
        Wir freuen uns über Ihren Besuch auf unserer Internetseite bxl.digital
        und Ihr Interesse an unserem Unternehmen. Der Schutz Ihrer
        personenbezogenen Daten, wie z.B. Geburtsdatum, Name, Telefonnummer,
        Anschrift etc., ist uns ein wichtiges Anliegen. Der Zweck dieser
        Datenschutzerklärung besteht darin, Sie über die Verarbeitung Ihrer
        personenbezogenen Daten zu informieren, die wir bei einem Seitenbesuch
        von Ihnen sammeln. Unsere Datenschutzpraxis steht im Einklang mit den
        gesetzlichen Regelungen der Datenschutzgrundverordnung der EU (DSGVO)
        und dem Bundesdatenschutzgesetz (BDSG). Die nachfolgende
        Datenschutzerklärung dient der Erfüllung der sich aus der DSGVO
        ergebenden Informationspflichten. Diese finden sich z.B. in Art. 13 und
        Art. 14 ff. DSGVO.
        <h2 id="jsectionanameresponsibleentityaverantwortlicher">
          <a name="responsible-entity"></a> Verantwortlicher
        </h2>
        Verantwortlicher im Sinne des Art. 4 Nr. 7 DSGVO ist derjenige, der
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten entscheidet. Im Hinblick auf
        unsere Internetseite ist der Verantwortliche: Beyond X Labs GmbH
        Arnsburger Straße 68 60385 Frankfurt Deutschland E-Mail:
        <a
          href="mailto:info@beyondxlabs.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@beyondxlabs.com
        </a>
        Tel.: +49(0)69 90754800
        <h2 id="jsectionbereitstellungderwebsiteunderstellungvonlogfiles">
          Bereitstellung der Website und Erstellung von Logfiles
        </h2>
        Bei jedem Aufruf unserer Internetseite erfasst unser System
        automatisiert Daten und Informationen des jeweils abrufenden Gerätes
        (z.B. Computer, Mobiltelefon, Tablet, etc.).
        <h4 id="jsectionwelchepersonenbezogenendatenwerdenerhobenundinwelchemumfangwerdendieseverarbeitet">
          Welche personenbezogenen Daten werden erhoben und in welchem Umfang
          werden diese verarbeitet?
        </h4>
        (1) Informationen über den Browsertyp und die verwendete Version; (2)
        Das Betriebssystem des Abrufgerätes; (3) Hostname des zugreifenden
        Rechners; (4) Die IP-Adresse des Abrufgerätes; (5) Datum und Uhrzeit des
        Zugriffs; (6) Websites und Ressourcen (Bilder, Dateien, weitere
        Seiteninhalte), die auf unserer Internetseite aufgerufen wurden; (7)
        Websites, von denen das System des Nutzers auf unsere Internetseite
        gelangte (Referrer-Tracking); (8) Meldung, ob der Abruf erfolgreich war;
        (9) Übertragene Datenmenge Diese Daten werden in den Logfiles unseres
        Systems gespeichert. Eine Speicherung dieser Daten zusammen mit
        personenbezogenen Daten eines konkreten Nutzers findet nicht statt, so
        dass eine Identifizierung einzelner Seitenbesucher nicht erfolgt.
        <h4 id="jsectionrechtsgrundlagefrdieverarbeitungpersonenbezogenerdaten">
          Rechtsgrundlage für die Verarbeitung personenbezogener Daten
        </h4>
        Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse). Unser berechtigtes
        Interesse besteht darin, die Erreichung des nachfolgend geschilderten
        Zwecks zu gewährleisten.
        <h4 id="jsectionzweckderdatenverarbeitung">
          Zweck der Datenverarbeitung
        </h4>
        Die vorübergehende (automatisierte) Speicherung der Daten ist für den
        Ablauf eines Websitebesuchs erforderlich, um eine Auslieferung der
        Website zu ermöglichen. Die Speicherung und Verarbeitung der
        personenbezogenen Daten erfolgt zudem zur Erhaltung der Kompatibilität
        unserer Internetseite für möglichst alle Besucher und zur
        Missbrauchsbekämpfung und Störungsbeseitigung. Hierfür ist es notwendig,
        die technischen Daten des abrufenden Rechners zu loggen, um so
        frühestmöglich auf Darstellungsfehler, Angriffe auf unsere IT-Systeme
        und/oder Fehler der Funktionalität unserer Internetseite reagieren zu
        können. Zudem dienen uns die Daten zur Optimierung der Website und zur
        generellen Sicherstellung der Sicherheit unserer informationstechnischen
        Systeme.
        <h4 id="jsectiondauerderspeicherung">Dauer der Speicherung</h4>
        Die Löschung der vorgenannten technischen Daten erfolgt, sobald sie
        nicht mehr benötigt werden, um die Kompatibilität der Internetseite für
        alle Besucher zu gewährleisten, spätestens aber 3 Monate nach Abruf
        unserer Internetseite.
        <h4 id="jsectionwiderspruchsundlschungsmglichkeit">
          Widerspruchs- und Löschungsmöglichkeit
        </h4>
        Sie können der Verarbeitung jederzeit gem. Art. 21 DSGVO widersprechen
        und eine Löschung von Daten gem. Art. 17 DSGVO verlangen. Welche Rechte
        Ihnen zustehen und wie Sie diese geltend machen, finden Sie im unteren
        Bereich dieser Datenschutzerklärung.
        <h2 id="jsectionbesonderefunktionenderinternetseite">
          Besondere Funktionen der Internetseite
        </h2>
        Unsere Seite bietet Ihnen verschiedene Funktionen, bei deren Nutzung von
        uns personenbezogene Daten erhoben, verarbeitet und gespeichert werden.
        Nachfolgend erklären wir, was mit diesen Daten geschieht:
        <h2 id="jsectionstatistischeauswertungderbesuchedieserinternetseitewebtracker">
          Statistische Auswertung der Besuche dieser Internetseite - Webtracker
        </h2>
        <p>
          Wir erheben, verarbeiten und speichern bei dem Aufruf dieser
          Internetseite oder einzelner Dateien der Internetseite folgende Daten:
          IP-Adresse, Webseite, von der aus die Datei abgerufen wurde, Name der
          Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und
          Meldung über den Erfolg des Abrufs (sog. Web-Log). Diese Zugriffsdaten
          verwenden wir ausschließlich in nicht personalisierter Form für die
          stetige Verbesserung unseres Internetangebots und zu statistischen
          Zwecken. Wir setzen zur Auswertung der Besuche dieser Internetseite
          zudem noch folgende Webtracker ein:
        </p>
        <ul>
          <li>
            <h3 id="jsectionanametracker418agoogleanalytics">
              <a name="tracker-418"></a> Google Analytics
            </h3>
            <p>
              Wir verwenden auf unserer Seite den Dienst Google Analytics des
              Unternehmens Google Ireland Ltd., Gordon House, Barrow Street, 4
              Dublin, Irland, E-Mail:{" "}
              <a
                href="mailto:support-deutschland@google.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                support-deutschland@google.com
              </a>
              , Website:{" "}
              <a
                href="https://www.google.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.google.com/
              </a>
              . Die Übermittlung personenbezogener Daten erfolgt auch in die
              USA. Im Hinblick auf die Übermittlung personenbezogener Daten in
              die USA besteht ein Angemessenheitsbeschlusses zum EU-US Data
              Privacy Framework der EU Kommission im Sinne des Art. 45 DSGVO
              (nachfolgend: DPF -{" "}
              <a
                href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en
              </a>{" "}
              ). Der Betreiber des Dienstes ist im Rahmen des DPF zertifiziert,
              so dass für die Übermittlung das übliche Schutzniveau der DSGVO
              gilt.
            </p>
            <p>
              Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten
              stellt Ihre Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO bzw. Art.
              9 Abs. 2 lit. a DSGVO dar, die Sie auf unserer Internetseite
              getätigt haben.
            </p>
            <p>
              Google Analytics ist ein Webtracker, der das Verhalten von
              Seitenbesuchern und deren Interaktionen mit unserer Website
              analysiert und uns Auswertungen und Prognosen über die Inhalte und
              Produkte unserer Webseite und deren Beliebtheit zur Verfügung
              stellt (sogenanntes Tracking). Wir haben Google Analytics
              eingebunden, damit der Dienst eine Analyse des Surfverhaltens der
              Seitennutzer zusammenstellen kann. Hierzu sammelt Google die
              Seiteninteraktionen von Seitenbesuchern mit unserer Website und
              ggf. bestehende Informationen die sich aus dem Auslesen von
              Cookies oder weiterer Speichertechnologien ergeben und bereitet es
              für uns statistisch auf. Google Analytics setzt
              Datenverarbeitungstechnologien ein, die eine Verfolgbarkeit von
              einzelnen Seitenbesuchern und deren Interaktion mit anderen
              Google-Diensten wie zu Beispiel dem Werbenetzwerk Google Ads
              ermöglichen. Daten aus weiteren Google-Diensten werden ebenfalls
              dazu eingesetzt um mittels maschineller Lerntechnologien,
              modellierter Statistiken und Prognosefunktionen Datenlücken zu
              schließen und umfangreiche Statistiken zu den Inhalten unserer
              Webseite zu erstellen. Sofern Google Analytics auf unserer Website
              aktiv geschaltet ist, werden die von Google Analytics ermittelten
              Daten an Server des Unternehmens Google Ireland Limited
              übertragen. Im Rahmen der Auftragsverarbeitung kann es auch zu
              einer Übermittlung von personenbezogenen Daten an die Server des
              Mutterkonzerns Google LLC, 1600 Amphitheatre Parkway, 94043
              Mountain View, Vereinigte Staaten kommen. Wir führen die Analyse
              durch Google Analytics durch, um unser Internetangebot ständig zu
              optimieren und besser verfügbar zu machen. Es handelt sich hierbei
              um eine sog. Reichweitenmessung.
            </p>
            <p>
              Zur Verarbeitung selbst erhebt der Dienst bzw. wir folgende Daten:
              Daten zu den Interaktionen der Seitenbesucher mit den Inhalten der
              Website, Daten zum Umgang der auf unserer Website dargestellten
              Leistungen, Daten von externen Google-Diensten, sofern sie mit
              unserer Website interagieren wie z.B. Werbedaten oder Daten zum
              Verhalten in Bezug auf Werbung, Daten zur groben geographischen
              Herkunft, dem verwendeten Browser, Betriebssystem sowie weiteren
              Informationen zum verwendeten Endgerät.
            </p>
            <p>
              Google Analytics wird die für die Bereitstellung des Webtracking
              relevanten Daten solange speichern, wie es notwendig ist, um den
              gebuchten Webservice zu erfüllen. Die Datenerhebung und
              Speicherung erfolgt anonymisiert. Soweit durch individuelle
              Interaktionen von Seitenbesuchern die Möglichkeit besteht, zu
              konkreten Handlungen nachträglich einen Personenbezug herzustellen
              werden wir die erhobenen Daten dann löschen, wenn der Zweck
              erreicht ist. Die Daten werden spätestens dann gelöscht, wenn
              diese keinen gesetzlichen Aufbewahrungspflichten unterfallen. Im
              Regelfall werden wir diese Daten spätestens nach 12 Monaten
              löschen. Die Zertifizierung des Anbieters im Rahmen des EU-US Data
              Privacy Frameworks können Sie unter{" "}
              <a
                href="https://www.dataprivacyframework.gov/list"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.dataprivacyframework.gov/list
              </a>{" "}
              abrufen.
            </p>
            <p>
              Ihre Einwilligung können Sie jederzeit widerrufen. Nähere
              Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder
              bei der Einwilligung selbst oder am Ende dieser
              Datenschutzerklärung.
            </p>
            <p>
              Weitere Informationen zum Handling der übertragenen Daten finden
              Sie in der Datenschutzerklärung des Anbieters unter{" "}
              <a
                href="https://policies.google.com/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://policies.google.com/privacy
              </a>
              .
            </p>
            <p>
              Der Anbieter bietet unter{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>{" "}
              zusätzlich eine Opt-Out Möglichkeit an.
            </p>
          </li>
          <li>
            <h3 id="jsectionanametracker112agoogletagmanager">
              <a name="tracker-112"></a> Google Tag Manager
            </h3>
            <p>
              Wir verwenden auf unserer Seite den Dienst Google Tag Manager des
              Unternehmens Google Ireland Ltd., Gordon House, Barrow Street, 4
              Dublin, Irland, E-Mail:{" "}
              <a
                href="mailto:support-deutschland@google.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                support-deutschland@google.com
              </a>
              , Website:{" "}
              <a
                href="https://www.google.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.google.com/
              </a>
              . Die Übermittlung personenbezogener Daten erfolgt auch in die
              USA. Im Hinblick auf die Übermittlung personenbezogener Daten in
              die USA besteht ein Angemessenheitsbeschlusses zum EU-US Data
              Privacy Framework der EU Kommission im Sinne des Art. 45 DSGVO
              (nachfolgend: DPF -{" "}
              <a
                href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en
              </a>{" "}
              ). Der Betreiber des Dienstes ist im Rahmen des DPF zertifiziert,
              so dass für die Übermittlung das übliche Schutzniveau der DSGVO
              gilt.
            </p>
            <p>
              Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten
              stellt Ihre Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO bzw. Art.
              9 Abs. 2 lit. a DSGVO dar, die Sie auf unserer Internetseite
              getätigt haben.
            </p>
            <p>
              Google Tag Manager bietet eine technische Plattform um andere
              Webtools und Webtracking-Programme mittels sogenannter „Tags“
              auszuführen und gebündelt steuern zu können. Google Tag Manager
              speichert in diesem Zusammenhang Cookies auf Ihrem Computer und
              analysiert, soweit Webtracking-Tools mittels Google Tag Manager
              ausgeführt werden, Ihr Surfverhalten (sogenanntes „tracken“). Die
              von den „Tags“ erzeugten Daten werden von Google Tag Manager unter
              einer einheitlichen Benutzeroberfläche zusammengeführt,
              gespeichert und verarbeitet. Alle eingebundenen „Tags“ werden in
              dieser Datenschutzerklärung nochmals gesondert aufgeführt. Im
              Rahmen der Benutzung unserer Webseite bei aktivierter Einbindung
              von „Tags“ von Google-Tag-Manager werden dabei Daten, wie
              insbesondere Ihre IP-Adresse und Ihre Nutzeraktivitäten an Server
              des Unternehmens Google übertragen. Die in Google Tag Manager
              verwendeten Tracking-Tools stellen durch eine IP-Anonymisierung
              des Quellcodes sicher, dass die IP-Adresse von Google Tag Manager
              vor der Übertragung anonymisiert wird. Mit Tag Manager lassen sich
              auf Basis der so genannten Tag-Verwaltung Messwerte verschiedener
              Dienstanbieter (Google und Drittanbieter) miteinander verknüpfen
              und auswerten. Google Tag Manager hilft uns Reports über die
              Websiteaktivitäten zusammenzustellen und die Webtools unserer
              Internetseite zu steuern.
            </p>
            <p>
              Zur Verarbeitung selbst erhebt der Dienst bzw. wir folgende Daten:
              Cookies, Webtracking-Daten, ausgehende oder eingehende Links,
              Informationen, die bei der Integration und Aktivierung von
              JavaScript-Code auf der Website entstehen von Google Tag Manager
              und der von Google Tag Manager ausgelösten Webtools.
            </p>
            <p>
              Die Zertifizierung des Anbieters im Rahmen des EU-US Data Privacy
              Frameworks können Sie unter{" "}
              <a
                href="https://www.dataprivacyframework.gov/list"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.dataprivacyframework.gov/list
              </a>{" "}
              abrufen.
            </p>
            <p>
              Ihre Einwilligung können Sie jederzeit widerrufen. Nähere
              Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder
              bei der Einwilligung selbst oder am Ende dieser
              Datenschutzerklärung.
            </p>
            <p>
              Weitere Informationen zum Handling der übertragenen Daten finden
              Sie in der Datenschutzerklärung des Anbieters unter{" "}
              <a
                href="https://policies.google.com/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://policies.google.com/privacy
              </a>
              .
            </p>
            <p>
              Der Anbieter bietet unter{" "}
              <a
                href="https://policies.google.com/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://policies.google.com/privacy
              </a>{" "}
              zusätzlich eine Opt-Out Möglichkeit an.
            </p>
          </li>
        </ul>
        <div id="jmd-section-cookies" data-markdown="1">
          <h2 id="jsectionunterrichtungberdienutzungvoncookies">
            <span style={textAlignStyle}>
              Unterrichtung über die Nutzung von Cookies
            </span>
          </h2>
          <ul>
            <li>
              <h4 id="jsectionwelchepersonenbezogenendatenwerdenerhobenundinwelchemumfangwerdendieseverarbeitet">
                <span style={textAlignStyle}>
                  Welche personenbezogenen Daten werden erhoben und in welchem
                  Umfang werden diese verarbeitet?
                </span>
              </h4>
              <p>
                <span style={textAlignStyle}>
                  Auf verschiedenen Seiten integrieren und verwenden wir
                  Cookies, um bestimmte Funktionen unserer Website zu
                  ermöglichen und externe Webservices zu integrieren. Bei den
                  sogenannten "Cookies" handelt es sich um kleine Textdateien,
                  die Ihr Browser auf Ihrem Zugangsgerät speichern kann. Diese
                  Textdateien enthalten eine charakteristische Zeichenkette, die
                  den Browser eindeutig identifiziert, wenn Sie zu unserer
                  Website zurückkehren. Der Prozess des Speicherns einer
                  Cookie-Datei wird auch als "Setzen eines Cookies" bezeichnet.
                  Cookies können hierbei sowohl von der Website selbst als auch
                  von externen Webservices gesetzt werden. Die Cookies werden
                  von unserer Website bzw. den externen Webservices gesetzt, um
                  die volle Funktionalität unserer Website zu erhalten, die
                  Benutzerfreundlichkeit zu verbessern oder um den mit Ihrer
                  Einwilligung angegebenen Zweck zu verfolgen. Die
                  Cookie-Technologie ermöglicht es uns auch, einzelne Besucher
                  anhand von Pseudonymen, z.B. einer individuellen oder
                  zufälligen IDs, zu erkennen, sodass wir mehr individuelle
                  Dienstleistungen anbieten können. Details sind in der
                  folgenden Tabelle aufgeführt.
                </span>
              </p>
            </li>
            <li>
              <h4 id="jsectionrechtsgrundlagefrdieverarbeitungpersonenbezogenerdaten">
                <span style={textAlignStyle}>
                  Rechtsgrundlage für die Verarbeitung personenbezogener Daten
                </span>
              </h4>
              <p>
                <span style={textAlignStyle}>
                  Soweit die Cookies auf Basis einer Einwilligung gemäß Art. 6
                  Abs. 1 lit. a DSGVO verarbeitet werden, gilt diese
                  Einwilligung auch als Einwilligung im Sinne des § 25 Abs. 1
                  TDDDG für das Setzen des Cookies auf dem Endgerät des Nutzers.
                  Soweit eine andere Rechtsgrundlage nach der DSGVO genannt wird
                  (z.B. zur Vertragserfüllung oder zur Erfüllung gesetzlicher
                  Pflichten), erfolgt die Speicherung bzw. das Setzen auf Basis
                  einer Ausnahme gemäß § 25 Abs. 2 TDDDG. Diese liegt dann vor,
                  „wenn der alleinige Zweck der Speicherung von Informationen in
                  der Endeinrichtung des Endnutzers oder der alleinige Zweck des
                  Zugriffs auf bereits in der Endeinrichtung des Endnutzers
                  gespeicherte Informationen die Durchführung der Übertragung
                  einer Nachricht über ein öffentliches Telekommunikationsnetz
                  ist“ oder „wenn die Speicherung von Informationen in der
                  Endeinrichtung des Endnutzers oder der Zugriff auf bereits in
                  der Endeinrichtung des Endnutzers gespeicherte Informationen
                  unbedingt erforderlich ist, damit der Anbieter eines
                  Telemediendienstes einen vom Nutzer ausdrücklich gewünschten
                  Telemediendienst zur Verfügung stellen kann“. Welche
                  Rechtsgrundlage einschlägig ist, ergibt sich aus der später in
                  diesem Punkt aufgeführten Cookie-Tabelle.
                </span>
              </p>
            </li>
            <li>
              <h4 id="jsectionzweckderdatenverarbeitung">
                <span style={textAlignStyle}>Zweck der Datenverarbeitung</span>
              </h4>
              <p>
                <span style={textAlignStyle}>
                  Die Cookies werden von unserer Website bzw. den externen
                  Webservices gesetzt, um die volle Funktionalität unserer
                  Website zu erhalten, die Benutzerfreundlichkeit zu verbessern
                  oder um den mit Ihrer Einwilligung angegebenen Zweck zu
                  verfolgen. Die Cookie-Technologie ermöglicht es uns auch,
                  einzelne Besucher anhand von Pseudonymen, z.B. einer
                  individuellen oder zufälligen IDs, zu erkennen, so dass wir
                  mehr individuelle Dienstleistungen anbieten können. Details
                  sind in der folgenden Tabelle aufgeführt.
                </span>
              </p>
            </li>
            <li>
              <h4 id="jsectiondauerderspeicherung">
                <span style={textAlignStyle}>Dauer der Speicherung</span>
              </h4>
              <p>
                <span style={textAlignStyle}>
                  Die Speicherung unserer Cookies erfolgt bis zur Löschung in
                  Ihrem Browser oder, wenn es sich um einen Session-Cookie
                  handelt, bis die Session abgelaufen ist. Details sind in der
                  folgenden Tabelle aufgeführt.
                </span>
              </p>
            </li>
            <li>
              <h4 id="jsectionwiderspruchsundbeseitigungsmglichkeit">
                <span style={textAlignStyle}>
                  Widerspruchs- und Beseitigungsmöglichkeit
                </span>
              </h4>
              <p>
                <span style={textAlignStyle}>
                  Sie können Ihren Browser nach Ihren Wünschen so einstellen,
                  dass das Setzen von Cookies generell verhindert wird. Sie
                  können dann von Fall zu Fall über die Annahme von Cookies
                  entscheiden oder Cookies grundsätzlich akzeptieren. Cookies
                  können für verschiedene Zwecke verwendet werden, z.B. um zu
                  erkennen, dass Ihr Zugangsgerät bereits mit unserer Website
                  verbunden ist (permanente Cookies) oder um zuletzt angesehene
                  Angebote zu speichern (Session-Cookies). Wenn Sie uns
                  ausdrücklich die Erlaubnis erteilt haben, Ihre
                  personenbezogenen Daten zu verarbeiten können Sie diese
                  Einwilligung jederzeit widerrufen. Bitte beachten Sie, dass
                  die Rechtmäßigkeit der auf der Grundlage der Einwilligung bis
                  zum Widerruf vorgenommenen Verarbeitung davon nicht berührt
                  wird.
                </span>
              </p>
            </li>
          </ul>
          <table className="cookie-table">
            <thead>
              <tr>
                <th>Cookie-Name</th>
                <th>Server</th>
                <th>Anbieter</th>
                <th>Zweck</th>
                <th>Rechtsgrundlage</th>
                <th>Speicherdauer</th>
                <th>Typ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_ga</td>
                <td>bxl.digital</td>
                <td>Google Analytics</td>
                <td>
                  Dieser Cookie ordnet einem User eine ID zu, damit der
                  Webtracker die Aktionen des Nutzers unter diese ID
                  zusammenfassen kann.
                </td>
                <td>
                  Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO
                  (Einwilligung)
                </td>
                <td>ca. 24 Monate</td>
                <td>Analytics</td>
              </tr>
              <tr>
                <td>_ga_</td>
                <td>bxl.digital</td>
                <td>Google Analytics</td>
                <td>
                  Dieses Cookie speichert im Zusammenhang mit Google Analytics
                  bzw. Google Tag Manager eine eindeutige ID für einen
                  Website-Besucher und verfolgt, wie der Besucher die Website
                  nutzt.
                </td>
                <td>
                  Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO
                  (Einwilligung)
                </td>
                <td>ca. 24 Monate</td>
                <td>Analytics</td>
              </tr>
              <tr>
                <td>cookie-consent</td>
                <td>bxl.digital</td>
                <td>Webseitenbetreiber</td>
                <td>
                  Das Cookie speichert die Einstellungen des Cookie-Banners und
                  die Entscheidung des Seitennutzers bezüglich der Cookies.
                </td>
                <td>
                  Art. 6 Abs. 1 lit. c DSGVO (Erfüllung rechtlicher
                  Verpflichtung)
                </td>
                <td>ca. 12 Monate</td>
                <td>Cookie-Banner</td>
              </tr>
              <tr>
                <td>pll_language</td>
                <td>bxl.digital</td>
                <td>Webseitenbetreiber</td>
                <td>
                  Dieses Cookie wird dazu verwendet, die Herkunft des Besuchers
                  mittels der IP-Adresse zu ermitteln und die Sprache der
                  Website anhand der Herkunft des Seitenzugriffs einzustellen.
                </td>
                <td>
                  Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO
                  (Einwilligung)
                </td>
                <td>Sitzung</td>
                <td>Komfort</td>
              </tr>
              <tr>
                <td>pll_language</td>
                <td>bxl.digital</td>
                <td>Webseitenbetreiber</td>
                <td>
                  Dieses Cookie wird dazu verwendet, die Herkunft des Besuchers
                  mittels der IP-Adresse zu ermitteln und die Sprache der
                  Website anhand der Herkunft des Seitenzugriffs einzustellen.
                </td>
                <td>
                  Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO
                  (Einwilligung)
                </td>
                <td>ca. 12 Monate</td>
                <td>Komfort</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 id="jsectionkontaktformulare">Nutzung von ChatGPT</h3>
        <p>
          Wir nutzen ChatGPT für unsere Dienstleistungen und
          Kundenkommunikation. Der Anbieter von ChatGPT ist OpenAI, mit Sitz in
          3180 18th St, San Francisco, CA 94110, USA. Sie können mehr darüber
          erfahren unter [<a href="https://openai.com">https://openai.com</a>](
          <a href="https://openai.com">https://openai.com</a>).
          <br /> <br />
          Wenn Sie über unsere Website eine Konversation mit uns beginnen und
          ChatGPT aktiviert ist, werden die von Ihnen bereitgestellten
          Informationen (einschließlich Metadaten) an die Server von ChatGPT
          übermittelt, wo sie verarbeitet werden, um eine angemessene Antwort zu
          generieren.
          <br />
          <br />
          Bitte beachten Sie, dass OpenAI sich das Recht vorbehält, den ChatGPT
          zur Verfügung gestellten Input zum Training und zur Verbesserung des
          Algorithmus zu verwenden. Wir haben keinen Einblick in die
          spezifischen Methoden, die OpenAI zur Verarbeitung dieser Daten
          verwendet.
          <br />
          <br />
          Unsere Nutzung von ChatGPT basiert auf Artikel 6(1)(f) der DSGVO und
          spiegelt unser berechtigtes Interesse an der Nutzung fortschrittlicher
          Technologie für eine effiziente Kundenkommunikation wider. Wenn wir
          Sie um eine Einwilligung gebeten haben und Sie diese erteilt haben,
          erfolgt die Verarbeitung ausschließlich auf der Grundlage von Artikel
          6 Absatz 1 Buchstabe a der DSGVO und § 25 Absatz 1 des TTDSG. Sie
          können Ihre Einwilligung jederzeit widerrufen.
          <br />
          <br />
          Weitere Informationen finden Sie in der Datenschutzrichtlinie von
          OpenAI: [
          <a href="https://openai.com/policies/privacy-policy">
            https://openai.com/policies/privacy-policy
          </a>
          ](
          <a href="https://openai.com/policies/privacy-policy">
            https://openai.com/policies/privacy-policy
          </a>
          ).
        </p>
        <h2 id="jsectioneinbindungexternerwebservicesundverarbeitungvondatenauerhalbdereu">
          Einbindung externer Webservices und Verarbeitung von Daten außerhalb
          der EU
        </h2>
        Auf unserer Internetseite verwenden wir aktive Inhalte von externen
        Anbietern, sog. Webservices. Durch Aufruf unserer Internetseite erhalten
        diese externen Anbieter ggf. personenbezogene Informationen über Ihren
        Besuch auf unserer Internetseite. Hierbei ist ggf. eine Verarbeitung von
        Daten außerhalb der EU möglich. Sie können dies verhindern, indem Sie
        ein entsprechendes Browser-Plugin installieren oder das Ausführen von
        Scripten in Ihrem Browser deaktivieren. Hierdurch kann es zu
        Funktionseinschränkungen auf Internetseiten kommen, die Sie besuchen.
        Wir verwenden folgende externe Webservices:
        <ul>
          <li>
            <h3 id="jsectionanametracker1200arechtstextsnippetundmodule">
              <a name="tracker-1200"></a> Rechtstextsnippet und Module
            </h3>
            Wir verwenden auf unserer Seite den Dienst Rechtstextsnippet und
            Module des Unternehmens Website-Check GmbH, Beethovenstraße 24,
            66111 Saarbrücken, Deutschland, E-Mail:
            <a
              href="mailto:support@website-check.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@website-check.de
            </a>
            , Website:
            <a
              href="https://www.website-check.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.website-check.de/
            </a>
            . Die Übermittlung personenbezogener Daten erfolgt ausschließlich an
            Server in der europäischen Union. Rechtsgrundlage für die
            Verarbeitung stellt Art. 6 Abs. 1 lit. c DSGVO dar. Der Einsatz des
            Dienstes unterstützt uns dabei unseren rechtlichen Verpflichtungen
            nachzukommen. Mit Hilfe des Dienstes werden auf unserer
            Internetseite Inhalte unserer Rechtstexte nachgeladen. Über die
            vorgenommene Einbindung auf unserer Seite werden die jeweils
            aktuellen Rechtstexte nachgeladen. Über diese Einbindung können ggf.
            auch weitere technische Module im Hinblick auf die rechtlichen Texte
            oder rechtlich notwendigen Elemente nachgeladen werden. Welche
            Rechte Ihnen im Hinblick auf die Verarbeitung zustehen, finden Sie
            am Ende dieser Datenschutzerklärung. Weitere Informationen zum
            Handling der übertragenen Daten finden Sie in der
            Datenschutzerklärung des Anbieters unter
            <a
              href="https://www.website-check.de/datenschutzerklaerung/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.website-check.de/datenschutzerklaerung/
            </a>
            .
          </li>
          <li>
            <h3 id="jsectionanametracker311awebsitechecksiegel">
              <a name="tracker-311"></a> Website-Check Siegel
            </h3>
            Wir verwenden auf unserer Seite den Dienst Website-Check Siegel des
            Unternehmens Website-Check GmbH, Beethovenstraße 24, 66111
            Saarbrücken, Deutschland, E-Mail:
            <a
              href="mailto:support@website-check.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@website-check.de
            </a>
            , Website:
            <a
              href="https://www.website-check.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.website-check.de/
            </a>
            . Die Übermittlung personenbezogener Daten erfolgt ausschließlich an
            Server in der europäischen Union. Rechtsgrundlage für die
            Verarbeitung der personenbezogenen Daten stellt gem. Art. 6 Abs. 1
            lit. f DSGVO unser berechtigtes Interesse dar. Unser berechtigtes
            Interesse liegt in der Erreichung des nachfolgend geschilderten
            Zwecks. Bei dem Script der Website-Check GmbH handelt es sich um die
            technische Einbindung des Website-Check Siegels. Mit diesem Siegel
            möchten wir zeigen, dass wir das Thema Datenschutz sehr ernst
            nehmen. Die Übermittlung von Daten an die Website-Check GmbH erfolgt
            zur Auslieferung und Darstellung des Siegels auf unserer Seite. Im
            Hinblick auf die Verarbeitung steht Ihnen das in Art. 21 aufgeführte
            Widerspruchsrecht zu. Nähere Informationen finden Sie am Ende dieser
            Datenschutzerklärung. Weitere Informationen zum Handling der
            übertragenen Daten finden Sie in der Datenschutzerklärung des
            Anbieters unter
            <a
              href="https://www.website-check.de/datenschutzerklaerung/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.website-check.de/datenschutzerklaerung/
            </a>
            .
          </li>
        </ul>
        <h2 id="jsectiondatensicherheitunddatenschutzkommunikationperemail">
          Datensicherheit und Datenschutz, Kommunikation per E-Mail
        </h2>
        Ihre personenbezogenen Daten werden durch technische und
        organisatorische Maßnahmen so bei der Erhebung, Speicherung und
        Verarbeitung geschützt, dass sie für Dritte nicht zugänglich sind. Bei
        einer unverschlüsselten Kommunikation per E-Mail kann die vollständige
        Datensicherheit auf dem Übertragungsweg zu unseren IT-Systemen von uns
        nicht gewährleistet werden, sodass wir bei Informationen mit hohem
        Geheimhaltungsbedürfnis eine verschlüsselte Kommunikation oder den
        Postweg empfehlen.
        <h2 id="jsectionauskunftsanspruchundberichtigungswnschelschungeinschrnkungvondatenwiderrufvoneinwilligungenwiderspruchsrecht">
          Auskunftsanspruch und Berichtigungswünsche – Löschung &amp;
          Einschränkung von Daten - Widerruf von Einwilligungen –
          Widerspruchsrecht
        </h2>
        <h3 id="jsectionauskunftsanspruch">Auskunftsanspruch</h3>
        Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob wir
        personenbezogene Daten von Ihnen verarbeiten. Sofern dies der Fall ist,
        haben Sie ein Recht auf Auskunft über die in Art. 15 Abs. 1 DSGVO
        benannten Informationen, soweit nicht die Rechte und Freiheiten anderer
        Personen beeinträchtigt werden (vgl. Art. 15 Abs. 4 DSGVO). Gerne
        stellen wir Ihnen auch eine Kopie der Daten zur Verfügung.
        <h3 id="jsectionberichtigungsanspruch">Berichtigungsanspruch</h3>
        Sie haben gem. Art. 16 DSGVO das Recht, bei uns ggf. falsch hinterlegte
        personenbezogene Daten (wie z.B. Adresse, Name, etc.) jederzeit
        korrigieren zu lassen. Auch können Sie jederzeit eine Vervollständigung
        der bei uns gespeicherten Daten verlangen. Eine entsprechende Anpassung
        erfolgt unverzüglich.
        <h3 id="jsectionrechtauflschung">Recht auf Löschung</h3>
        Sie haben gem. Art. 17 Abs. Abs. 1 DSGVO das Recht darauf, dass wir die
        über Sie erhobenen personenbezogenen Daten löschen, wenn
        <ul>
          <li>die Daten entweder nicht mehr benötigt werden;</li>
          <li>
            aufgrund des Widerrufs Ihrer Einwilligung die Rechtsgrundlage der
            Verarbeitung ersatzlos entfallen ist;
          </li>
          <li>
            Sie Widerspruch gegen die Verarbeitung eingelegt haben und keine
            berechtigten Gründe für die Verarbeitung vorliegen;
          </li>
          <li>Ihre Daten unrechtmäßig verarbeitet werden;</li>
          <li>
            eine rechtliche Verpflichtung dies erfordert oder eine Erhebung gem.
            Art. 8 Abs. 1 DSGVO stattgefunden hat.
          </li>
        </ul>
        Das Recht besteht gem. Art. 17 Abs. 3 DSGVO dann nicht, wenn
        <ul>
          <li>
            die Verarbeitung zur Ausübung des Rechtes auf freie Meinungsäußerung
            und Information erforderlich ist;
          </li>
          <li>
            Ihre Daten auf Grundlage einer rechtlichen Verpflichtung erhoben
            worden sind;
          </li>
          <li>
            die Verarbeitung aus Gründen des öffentlichen Interesses
            erforderlich ist;
          </li>
          <li>
            die Daten zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen erforderlich sind.
          </li>
        </ul>
        <h3 id="jsectionrechtaufeinschrnkungderverarbeitung">
          Recht auf Einschränkung der Verarbeitung
        </h3>
        Gem. Art. 18 Abs. 1 DSGVO haben Sie in einzelnen Fällen das Recht, die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Dies ist dann der Fall, wenn
        <ul>
          <li>
            die Richtigkeit der personenbezogenen Daten von Ihnen bestritten
            wird;
          </li>
          <li>
            die Verarbeitung unrechtmäßig ist und Sie einer Löschung nicht
            zustimmen;
          </li>
          <li>
            die Daten nicht länger für den Verarbeitungszweck benötigt werden,
            aber die erhobenen Daten der Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen dienen;
          </li>
          <li>
            ein Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DSGVO
            eingelegt worden ist und noch unklar ist, welche Interessen
            überwiegen.
          </li>
        </ul>
        <h3 id="jsectionrechtaufwiderruf">Recht auf Widerruf</h3>
        Sofern Sie uns eine ausdrückliche Einwilligung in die Verarbeitung Ihrer
        personenbezogenen Daten erteilt haben (Art. 6 Abs. 1 lit. a DSGVO bzw.
        Art. 9 Abs. 2 lit. a DSGVO) können Sie diese jederzeit widerrufen. Bitte
        beachten Sie, dass die Rechtmäßigkeit der aufgrund der Einwilligung bis
        zum Widerruf erfolgten Verarbeitung hierdurch nicht berührt wird.
        <h3 id="jsectionrechtaufwiderspruch">Recht auf Widerspruch</h3>
        Sie haben gem. Art. 21 DSGVO das Recht, jederzeit gegen die Verarbeitung
        Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1
        lit. f (im Rahmen eines berechtigten Interesses) erhoben worden sind,
        Widerspruch einzulegen. Das Recht steht Ihnen nur zu, wenn gegen die
        Speicherung und Verarbeitung besondere Umstände sprechen.
        <h3 id="jsectionwienehmensieihrerechtewahr">
          Wie nehmen Sie Ihre Rechte wahr?
        </h3>
        Ihre Rechte können Sie jederzeit wahrnehmen, indem Sie sich an die unten
        stehenden Kontaktdaten wenden: Beyond X Labs GmbH Arnsburger Straße 68
        60385 Frankfurt Deutschland E-Mail:
        <a
          href="mailto:info@beyondxlabs.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@beyondxlabs.com
        </a>
        Tel.: +49(0)69 90754800
        <h2 id="jsectionrechtaufdatenbertragbarkeit">
          Recht auf Datenübertragbarkeit
        </h2>
        Sie haben gem. Art. 20 DSGVO einen Anspruch auf Übermittlung der Sie
        betreffenden personenbezogenen Daten. Die Daten werden von uns in einem
        strukturierten, gängigen und maschinenlesbaren Format zur Verfügung
        gestellt. Die Daten können hierbei wahlweise an Sie selbst oder an einen
        von Ihnen benannten Verantwortlichen übersendet werden. Wir stellen
        Ihnen auf Anfrage gem. Art. 20 Abs. 1 DSGVO folgende Daten bereit:
        <ul>
          <li>
            Daten, die aufgrund einer ausdrücklichen Einwilligung gem. Art. 6
            Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO erhoben wurden;
          </li>
          <li>
            Daten, die wir gemäß gem. Art. 6 Abs. 1 lit. b DSGVO im Rahmen
            bestehender Verträge von Ihnen erhalten haben;
          </li>
          <li>
            Daten, die im Rahmen eines automatisierten Verfahrens verarbeitet
            worden sind.
          </li>
        </ul>
        Die Übertragung der personenbezogenen Daten direkt an einen von Ihnen
        gewünschten Verantwortlichen werden wir vornehmen, soweit dies technisch
        machbar ist. Bitte beachten Sie, dass wir Daten, die in die Freiheiten
        und Rechte anderer Personen eingreifen gem. Art. 20 Abs. 4 DSGVO nicht
        übertragen dürfen.
        <h2 id="jsectionbeschwerderechtbeideraufsichtsbehrdegemart77abs1dsgvo">
          Beschwerderecht bei der Aufsichtsbehörde gem. Art. 77 Abs. 1 DSGVO
        </h2>
        Sofern Sie den Verdacht haben, dass auf unserer Seite Ihre Daten
        rechtswidrig verarbeitet werden, können Sie selbstverständlich jederzeit
        eine gerichtliche Klärung der Problematik herbeiführen. Zudem steht
        Ihnen jede andere rechtliche Möglichkeit offen. Unabhängig davon steht
        Ihnen gem. Art. 77 Abs. 1 DSGVO die Möglichkeit zur Verfügung, sich an
        eine Aufsichtsbehörde zu wenden. Das Beschwerderecht gem. Art. 77 DSGVO
        steht Ihnen in dem EU-Mitgliedstaat Ihres Aufenthaltsortes, Ihres
        Arbeitsplatzes und/oder des Ortes des vermeintlichen Verstoßes zu, d.h.
        Sie können die Aufsichtsbehörde, an die Sie sich wenden, aus den oben
        genannten Orten wählen. Die Aufsichtsbehörde, bei der die Beschwerde
        eingereicht wurde, unterrichtet Sie dann über den Stand und die
        Ergebnisse Ihrer Eingabe, einschließlich der Möglichkeit eines
        gerichtlichen Rechtsbehelfs gem. Art. 78 DSGVO.
      </div>
    </div>
  );
};

export default withTranslation()(PrivacyPolicy);
