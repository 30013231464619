import React, { useEffect } from "react";
import "./style.scss";

import { withTranslation } from "react-i18next";

const Impressum = (props) => {
  const { t } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-page">
      <div id="jmdwrapper-1708545133891" data-jmdwrapper="1708545133891">
        <div id="jmdcontent" data-jid="581752" data-jtype="imprint" lang="de">
          <h1 id="jsectionimpressum">Impressum</h1>
          <h2 id="jsectionseitenbetreiber">Seitenbetreiber</h2>
          <p>
            Beyond X Labs GmbH
            <br />
            Wallstraße 8<br />
            60594 Frankfurt am Main
            <br />
            Deutschland
            <br />
            E-Mail:{" "}
            <a
              href="mailto:info@beyondxlabs.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              info@beyondxlabs.com
            </a>
            <br />
            Tel.: +49(0)69 20025078
          </p>
          <h3 id="jsectionhandelsregister">Handelsregister</h3>
          <p>
            Registergericht: Amtsgericht Frankfurt am Main
            <br />
            Registernummer: HRB 119046
          </p>
          <h3 id="jsectiongeschftsfhrer">Geschäftsführer</h3>
          <p>Christoph Mangold</p>
          <h3 id="jsectionumsatzsteueridentifikationsnummergem27aumsatzsteuergesetz">
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz
          </h3>
          <p>DE 330105784</p>
          <h3 id="jsectionurheberrechtundbildnachweise">
            Urheberrecht und Bildnachweise
          </h3>
          <p>
            Die Inhalte von findadog.de sind – soweit nicht abweichend angegeben
            – urheberrechtlich geschützt.
            <br />
            Verwendete Fotografien sind ggf. mit Bildnachweisen gekennzeichnet
            oder unten aufgeführt, soweit sie nicht selbst angefertigt wurden.
            <br />
            Die Verwendung von Fotografien auf Drittseiten ist nur im Rahmen der
            jeweiligen Lizenz der Urheber möglich.
          </p>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Impressum);
