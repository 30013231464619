import React, { useState, useEffect } from "react";
import { Button, TextField, Box, Rating, Container } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { withTranslation } from "react-i18next";
import StarIcon from "@mui/icons-material/Star";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserFeedback = ({
  t,
  ans,
  onClickHandler,
  refresh,
  selectedDogs,
  resetSelected,
  dislikedItems,
}) => {
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selected_breeds: selectedDogs || [],
      disliked_breeds: dislikedItems || [],
    }));
  }, [selectedDogs, dislikedItems]);

  const [state, setState] = useState({
    rating: null,
    comment: "",
    answers: ans,
    selected_breeds: selectedDogs || [],
    disliked_breeds: dislikedItems || [],
  });

  const API_URL = `${process.env.REACT_APP_SERVER_URI}/`;
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const handleFeedback = async (e) => {
    e.preventDefault();
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(state),
    };

    // if (state.selected_breeds.length === 0) {
    //   setAlertMessage(t("invalid_breeds"));
    //   setAlertSeverity("error");
    //   setAlert(true);
    //   return;
    // }

    if (state.rating <= 0) {
      setAlertMessage(t("invalid_rating"));
      setAlertSeverity("error");
      setAlert(true);
      return;
    }

    if (!state.comment.trim()) {
      setAlertMessage(t("invalid_comment"));
      setAlertSeverity("error");
      setAlert(true);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URI}/api/v1/feedback`, requestOptions);

      if (response.ok) {
        setAlertMessage(t("thanks_feedback"));
        setAlertSeverity("success");
        setAlert(true);
        setState({
          rating: 0,
          comment: "",
        });
        resetSelectedAll();
      } else {
        setAlertMessage(t("feedback_error"));
        setAlertSeverity("error");
        setAlert(true);
      }
    } catch (error) {
      setAlertMessage(t("feedback_error"));
      setAlertSeverity("error");
      setAlert(true);
    }
  };

  const handleClick = () => {
    if (typeof onClickHandler === "function") {
      onClickHandler();
    }
  };

  const resetSelectedAll = () => {
    if (typeof resetSelected === "function") {
      resetSelected();
    }
  };

  return (
    <>
      <Container>
        <Snackbar
          open={alert}
          autoHideDuration={4000}
          onClose={() => setAlert(false)}
        >
          <Alert
            onClose={() => setAlert(false)}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <div
          style={{
            textAlign: "center",
            paddingBottom: "50px",
            paddingTop: "20px",
          }}
        >
          <hr />
          {/* {!refresh ? (
            <p style={{ marginBottom: "0px" }}>
              {t("looking_for")}{" "}
              <span
                style={{
                  color: "#E44142",
                  borderBottom: "1px solid #E44142",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                {t("click_here")}
              </span>
            </p>
          ) : (
            <p style={{ marginBottom: "0px" }}>{t("more_option_text")}</p>
          )} */}

          <br />
          <div className="borderBottomCreate"></div>
          <div className="form feedback-form">
            <Box component="form" onSubmit={handleFeedback} autoComplete="off">
              <Rating
                style={{ fontSize: "48px" }}
                precision={0.5}
                value={state.rating}
                onChange={(e) => setState({ ...state, rating: e.target.value })}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              <br />
              <h4 style={{ color: "#B0B0B0", fontWeight: "500" }}>
                {t("your_feedback_matters_to_us")}
              </h4>
              <br />
              <TextField
                size="small"
                multiline
                rows={3}
                maxRows={5}
                placeholder={t("anything_help")}
                value={state.comment}
                onChange={(e) =>
                  setState({ ...state, comment: e.target.value })
                }
                fullWidth
              />
              <br />
              <br />
              <div className="feedback-page-btn">
                <Button
                  variant="contained"
                  className="feedback-btn"
                  type="submit"
                >
                  {t("submit_feedback")}
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withTranslation()(UserFeedback);
