import FindADog from "../../../Assets/images/find.jpg";

const Questions = [
  {
    name: "multiple-choice",
    id: "1",
    attributes: {
      required: true,

      label: "Wie groß kann/sollte dein Hund sein (Schulterhöhe)?",
      choices: [
        {
          label: "Klein (bis zu 35cm)",
          value: "Klein (bis zu 35cm)",
        },
        {
          label: "Mittel (35-60cm)",
          value: "Mittel (35-60cm)",
        },
        {
          label: "Groß (über 60cm)",
          value: "Groß (über 60cm)",
        },
        {
          label: "Keine Präferenz",
          value: "Keine Präferenz",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "2",
    attributes: {
      required: true,

      label: "Wie viel Auslauf kannst du deinem Hund täglich bieten?",
      choices: [
        {
          label: "20-30 Min.",
          value: "20-30 Min.",
        },
        {
          label: "30-60 Min.",
          value: "30-60 Min.",
        },
        {
          label: "60-90 Min.",
          value: "60-90 Min.",
        },
        {
          label: "90-120 Min.",
          value: "90-120 Min.",
        },
        {
          label: "Keine Präferenz",
          value: "Keine Präferenz",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "3",
    attributes: {
      required: true,
      label: "Wie viel Bellen ist für dich in Ordnung?",
      choices: [
        {
          label: "so wenig wie möglich",
          value: "so wenig wie möglich",
        },
        {
          label: "etwas Bellen",
          value: "etwas Bellen",
        },
        {
          label: "viel Bellen",
          value: "viel Bellen",
        },
        {
          label: "keine Präferenz",
          value: "keine Präferenz",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "4",
    attributes: {
      required: true,

      label: "Wie sieht dein Zuhause aus?",
      choices: [
        {
          label: "Kleine Wohnung",
          value: "Kleine Wohnung",
        },
        {
          label: "Große Wohnung",
          value: "Große Wohnung",
        },
        {
          label: "Haus mit kleinem Garten",
          value: "Haus mit kleinem Garten",
        },
        {
          label: "Haus mit großem Garten",
          value: "Haus mit großem Garten",
        }
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "5",
    attributes: {
      required: true,

      label: "Wie viele Stunden wäre dein Hund an einem normalen Arbeitstag allein?",
      description: "Bitte beachte, dass wir mit den Antwortmöglichkeiten nicht nahelegen möchten, Deinen Hund ggfs. bis zu 6 Stunden alleine zu lassen. Längeres Alleinsein kann bei Hunden zu Stress, Angstzuständen und anderen Problemen führen. Dein vierbeiniger Freund freut sich über Gesellschaft und Zuwendung! Bitte befrage im konkreten Fall den Züchter oder eine andere auskunftsfähige Person.",
      choices: [
        {
          label: "0-3 Stunden",
          value: "0-3 Stunden",
        },
        {
          label: "3-6 Stunden",
          value: "3-6 Stunden",
        }
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "6",
    attributes: {
      required: true,
      label: "Hast du Kinder im Alter von 0-10 Jahren im Haus?",
      choices: [
        {
          label: "Ja",
          value: "Ja",
        },
        {
          label: "Nein",
          value: "Nein",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "7",
    attributes: {
      required: true,
      label: "Wie hoch ist dein monatliches Budget für deinen vierbeinigen Freund?",
      description: "Vergiss bei der Planung deines monatlichen Budgets für deinen vierbeinigen Freund nicht, zusätzliche Kosten wie Training, Tierversicherung und gelegentliche Unterbringung oder Tierbetreuung zu berücksichtigen. Diese Ausgaben können zu den regulären Kosten für Futter, Pflege und tierärztliche Versorgung hinzugerechnet werden, um sicherzustellen, dass dein Hund glücklich, gesund und wohlerzogen bleibt!",
      choices: [
        {
          label: "25-100€",
          value: "25-100€",
        },
        {
          label: "100-200€",
          value: "100-200€",
        },
        {
          label: "200€ +",
          value: "200€ +",
        }
      ],
    },
  }
];

export default Questions;
