import React, { useEffect, useState } from 'react';
import './header.scss';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router';


const Header = () => {

  const { i18n } = useTranslation();
  const [lang, setLang] = useState('de');
  const naviagte = useNavigate()

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem('langFlag', event.target.value);
    setLang(event.target.value)
  };

  useEffect(() => {
    let val = localStorage.getItem('langFlag');
    
    if (val) {
      i18n.changeLanguage(val);
      setLang(val);
     
    }
  }, [])


  return (
    <header className="header">
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}><button role='button' onClick={() => {naviagte('/')}} className='logo'>FindaDog</button></div>


      <nav className='langeswitch'>
     
          <Select
          value={lang}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="de">DE</MenuItem>
          <MenuItem value="en">EN</MenuItem>
        </Select>
      </nav>
    </header>
  )
}

export default Header