import FindADog from "../../../Assets/images/find.jpg";

const Questions = [
  // {
  //   name: "welcome-screen",
  //   id: "jg1401r",
  //   attributes: {
  //     label: "Find a Dog!",
  //     description: "You're just few steps away from picking your best friend",
  //     attachment: {
  //       type: "image",
  //       url: FindADog
  //     }
  //   }
  // },
  {
    name: "multiple-choice",
    id: "8",
    attributes: {
      required: true,
      label: "Welche Felllänge bevorzugst Du?",
      choices: [
        {
          label: "Kurz",
          value: "Kurz",
        },
        {
          label: "Mittel",
          value: "Mittel",
        },
        {
          label: "Lang",
          value: "Lang",
        },
        {
          label: "Keine Präferenz",
          value: "Keine Präferenz",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "9",
    attributes: {
      required: true,

      label: "Wie viel Haaren des Hundes ist für Dich akzeptabel?",
      choices: [
        {
          label: "Haaren stört mich nicht",
          value: "Haaren stört mich nicht",
        },
        {
          label: "Durchschnittliches Haaren ist in Ordnung",
          value: "Durchschnittliches Haaren ist in Ordnung",
        },
        {
          label: "Ich möchte einen Hund, der möglichst wenig haart",
          value: "Ich möchte einen Hund, der möglichst wenig haart",
        },
        {
          label: "Keine Präferenz",
          value: "Keine Präferenz",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "10",
    attributes: {
      required: true,
      label: "Hast Du eine bevorzugte Fellfarbe?",
      choices: [
        {
          label: "Schwarz",
          value: "Schwarz",
        },
        {
          label: "Weiß",
          value: "Weiß",
        },
        {
          label: "Braun",
          value: "Braun",
        },
        {
          label: "Keine Präferenz",
          value: "Keine Präferenz",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "11",
    attributes: {
      required: true,
      label: "Wie trainierbar soll dein Hund sein?",
      choices: [
        {
          label: "Leicht trainierbar",
          value: "Leicht trainierbar",
        },
        {
          label: "Trainierbar",
          value: "Trainierbar",
        },
        {
          label: "Schwer trainierbar",
          value: "Schwer trainierbar",
        },
        {
          label: "Keine Präferenz",
          value: "Keine Präferenz",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "12",
    attributes: {
      required: true,

      label:
        "Wie viel Zeit hast du an einem normalen Tag für Training und Übungen mit dem Hund?",
      choices: [
        {
          label: "0-30 Minuten",
          value: "0-30 Minuten",
        },
        {
          label: "30-60 Minuten",
          value: "30-60 Minuten",
        },
        {
          label: "1-2 Stunden",
          value: "1-2 Stunden",

        },
        {
          label: "Mehr als 2 Stunden",
          value: "Mehr als 2 Stunden",
        },
        {
          label: "Keine Präferenz",
          value: "Keine Präferenz",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "13",
    attributes: {
      required: true,
      label: "Wie häufig kannst du die Pflege deines Hundes durchführen?",
      description: "Zur Pflege deines Hundes gehört: Fellpflege, Zahnpflege, Krallenpflege, Ohrenpflege, Augenpflege, körperliche Bewegung und Gesundheitsvorsorge.",
      choices: [
        {
          label: "Monatlich",
          value: "Monatlich",
        },
        {
          label: "Zweimal im Monat",
          value: "Zweimal im Monat",
        },
        {
          label: "Jede Woche",
          value: "Jede Woche",
        },
        {
          label: "Keine Präferenz",
          value: "Keine Präferenz",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "14",
    attributes: {
      required: true,
      label:
        "Hast du als Erwachsener jemals einen Hund besessen oder hast du Erfahrung mit Hunden?",
      choices: [
        {
          label: "Ja",
          value: "Ja",
        },
        {
          label: "Nein",
          value: "Nein",
        },
        {
          label: "Keine Präferenz",
          value: "Keine Präferenz",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "15",
    attributes: {
      required: true,
      label:
        "Gibt es in deinem Haushalt jemanden, der allergisch auf Hunde reagiert?",
      choices: [
        {
          label: "Ja",
          value: "Ja",
        },
        {
          label: "Nein",
          value: "Nein",
        },
      ],
    },
  },
  {
    name: "multiple-choice",
    id: "16",
    attributes: {
      required: true,
      label:
        "Sollen Hunderassen, die in Deutschland in der Rasseliste stehen, ausgeschlossen werden?",
      description: 'Hunde auf der deutschen "Rasseliste" gelten als potenziell gefährlich. Für sie gibt es spezielle Regelungen wie Maulkorb- und Leinenpflicht, Haltegenehmigungen und weitere Auflagen zur Erhöhung der öffentlichen Sicherheit.',
      choices: [
        {
          label: "Ja",
          value: "Ja",
        },
        {
          label: "Nein",
          value: "Nein",
        },
        {
          label: "Keine Präferenz",
          value: "Keine Präferenz",
        },
      ],
    },
  },
];

export default Questions;
