import React, { useEffect, useState } from "react";
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import Loader from "../../Global/Loader";
import Recommendations from "../ShowResults/Recommendations";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import UserFeedback from "../ShowResults/UserFeedback";
import Questions from "./Questions";
import QuestionsDe from "./QuestionsDe";
import QuestionsMore from "./QuestionsMore";
import QuestionsMoreDe from "./QuestionsMoreDe";
import ChoosePriority from "./ChoosePriority";
import "./Qna.scss";
import { useTranslation } from "react-i18next";
import legImage from "../../../Assets/images/leg.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import Footer from '../../Global/Footer'

registerCoreBlocks();

const QnA = () => {
  const [loading, setLoading] = useState(true);
  const [choose, setChoose] = useState(false);
  const [ques, setQues] = useState([]);
  const [ques1, setQues1] = useState([]);
  const [result, setResult] = useState();
  const [breed, setBreed] = useState([]);
  const { t, i18n } = useTranslation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [refreshResults, setRefreshResults] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [moreResultRequest, setMoreResultRequest] = useState(false);
  const [previousResults, setPreviousResults] = useState(null);

  const [selectedItems, setSelectedItems] = useState([]);
  const [dislikedItems, setDislikedItems] = useState([]);
  const [resetItems, setResetItems] = useState(false);
  let navigate = useNavigate();

  const settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);

    setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, [selectedItems, dislikedItems]);

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  // const handleSelectItem = async (value) => {
  //   let tmp = selectedItems.length > 0 ? selectedItems : [];

  //   if (tmp.includes(value)) {
  //     let t = tmp.filter((i) => i != value);
  //     setSelectedItems([...t]);
  //   } else {
  //     if (tmp.length < 3) {
  //       let t = [...tmp, value];
  //       setSelectedItems([...t]);
  //     }
  //   }
  // };

  const handleSelectItem = (value) => {
    let tmpLiked = selectedItems.length > 0 ? selectedItems : [];

    if (tmpLiked.includes(value)) {
      let updatedLiked = tmpLiked.filter((i) => i !== value);
      setSelectedItems([...updatedLiked]);
    } else {
      let updatedLiked = [...tmpLiked, value];
      setSelectedItems([...updatedLiked]);
      setDislikedItems(dislikedItems.filter((i) => i !== value));
    }
  };

  const handleDislikeItem = (value) => {
    let tmpDisliked = dislikedItems.length > 0 ? dislikedItems : [];

    if (tmpDisliked.includes(value)) {
      let updatedDisliked = tmpDisliked.filter((i) => i !== value);
      setDislikedItems([...updatedDisliked]);
    } else {
      let updatedDisliked = [...tmpDisliked, value];
      setDislikedItems([...updatedDisliked]);
      setSelectedItems(selectedItems.filter((i) => i !== value));
    }
  };

  const handleResult = async (res) => {
    setLoading(true);
    const mergedArray = [...ques, ...ques1];

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("language", i18n.language ? i18n.language : "de");

    let arr = [];

    let selectedRes = moreResultRequest ? mergedArray : res;

    for (let i = 0; i < selectedRes.length; i++) {
      if (choose) {
        if (choose && res.includes(i + 1)) {
          arr.push(
            selectedRes[i] +
              ". Please give this question more weightage than others."
          );
        } else {
          arr.push(selectedRes[i]);
        }
      } else {
        arr.push(selectedRes[i]);
      }
    }

    //arr.push(res);

    let raw = JSON.stringify({
      response: res,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    try {
      const req = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/api/v1/find-dog-test-refresh`,
        requestOptions
      );

      if (req.ok) {
        const res = await req.json();

        const output = res?.info.filter(item => item!== null);

        // let index = res?.data?.breeds.filter((item, index) => item === null ? index : null);
        // console.log(index);

        setBreed(output);
        setResult(res?.data?.breeds);
        setLoading(false);
        setChoose(false);
        setRequestSuccess(false);
      } else {
        setLoading(false);
        setChoose(false);
        setRequestSuccess(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setChoose(false);
      setRequestSuccess(true);
    }
  };

  const handleChildClick = async () => {
    setRefreshResults(true);
  };

  const resetSelectedItems = async () => {
    setSelectedItems([]);
    setDislikedItems([]);
    setResetItems(true);
  };

  const handleBack = () => {
    setChoose(false);
  };

  const moreResult = () => {
    setRequestSuccess(false);
    setResult(null);
    setChoose(false);
    setMoreResultRequest(true);
    setSelectedItems([]);
    setDislikedItems([]);
    setPreviousResults(result);
  };

  const backButton = () => {
    setMoreResultRequest(false);

    setResult(previousResults);
  };

  if (loading) return <Loader />;
  // else if (choose)
  //   return (
  //     <>
  //       <div className="back-btn-div">
  //         <Button variant="contained" className="btn-back" onClick={handleBack}>
  //           {t("back")}
  //         </Button>
  //       </div>
  //       <ChoosePriority ans={ques1} handleResult={handleResult} />
  //     </>
  //   );
  // else
  return (
    <>
      {requestSuccess ? (
        <div className="error_msg">{t("error_msg_text")}</div>
      ) : result === undefined || result === null ? (
        <div style={{ width: "100%" }} className="page">
          <img src={legImage} className="dog-leg" />

          {moreResultRequest && (
            <div className="back_btn_div1">
              <span className="btn-back1" onClick={backButton}>
                {t("back")}
              </span>
            </div>
          )}

          <span className="swipe-text">{t("navigate_the_form")}</span>
          {!moreResultRequest ? (
            <Form
              formId="1"
              formObj={{
                blocks: i18n.language === "de" ? QuestionsDe : Questions,
                settings: {
                  animationDirection: "vertical",
                  disableWheelSwiping: false,
                  disableNavigationArrows: false,
                  disableProgressBar: true,
                  showQuestionsNumbers: false,
                  showLettersOnAnswers: false,
                },
                theme: {
                  font: "Poppins",
                  buttonsBgColor: "#E44142",
                  questionsColor: "#2C291E",
                  answersColor: "#E44142",
                  buttonsFontColor: "#fff",
                  buttonsBorderRadius: 25,
                  errorsFontColor: "#fff",
                  errorsBgColor: "#f00",
                  progressBarFillColor: "#E44142",
                  progressBarBgColor: "#ccc",
                },
                messages: {
                  "label.submitBtn": t("submit"),
                  "label.errorAlert.required": t("required_field"),
                },
              }}
              onSubmit={(data, { setIsSubmitting }) => {
                setTimeout(() => {
                  let res = [];

                  for (let i = 1; i < 7; i++) {
                    if (
                      data.answers[i.toString()].blockName === "multiple-choice"
                    ) {
                      res.push(data.answers[i.toString()].value.join(", "));
                    } else {
                      res.push(data.answers[i.toString()].value);
                    }
                  }

                  setQues(res);
                  setIsSubmitting(false);
                  handleResult(res);
                  // setChoose(true);
                }, 0);
              }}
            />
          ) : (
            <Form
              formId="2"
              formObj={{
                blocks:
                  i18n.language === "de" ? QuestionsMoreDe : QuestionsMore,
                settings: {
                  animationDirection: "vertical",
                  disableWheelSwiping: false,
                  disableNavigationArrows: false,
                  disableProgressBar: true,
                  showQuestionsNumbers: false,
                  showLettersOnAnswers: false,
                },
                theme: {
                  font: "Poppins",
                  buttonsBgColor: "#E44142",

                  questionsColor: "#2C291E",
                  answersColor: "#E44142",
                  buttonsFontColor: "#fff",
                  buttonsBorderRadius: 25,
                  errorsFontColor: "#fff",
                  errorsBgColor: "#f00",
                  progressBarFillColor: "#E44142",
                  progressBarBgColor: "#ccc",
                },
                messages: {
                  "label.submitBtn": t("submit"),
                  "label.errorAlert.required": t("required_field"),
                },
              }}
              onSubmit={(data, { setIsSubmitting }) => {
                setTimeout(() => {
                  let res1 = [];

                  for (let i = 8; i < 17; i++) {
                    if (
                      data.answers[i.toString()].blockName === "multiple-choice"
                    ) {
                      res1.push(data.answers[i.toString()].value.join(", "));
                    } else {
                      res1.push(data.answers[i.toString()].value);
                    }
                  }

                  setQues1(res1);
                  setIsSubmitting(false);
                  handleResult(res1);
                  //setChoose(true);
                }, 0);
              }}
            />
          )}
        </div>
      ) : (
        <>
          <div className="mobile-text">
            {/* <span className="count">
                 {t("results")}: {refreshResults ? 6 : 3}
              </span> */}
            <span className="count-text">{t("tap_to_flip")}</span>
          </div>
          {/* {!moreResultRequest && (
              <div className="more_result_div">
                <span className="more_result" onClick={moreResult}>
                  {t("get_more_result")}
                </span>
              </div>
            )} */}
          <div className="gpt-result">
            <>
              <div className="mobile-text">
                {/* <span>Results: {refreshResults ? 6 : 3}</span>
                  <span>Tap to flip card, swipe to see next</span> */}
              </div>

              <Grid container className="gpt-result-grid">
                {breed &&
                  //breed.slice(0, refreshResults ? 6 : 3).map(
                  breed.map(
                    (item, index) =>
                      item !== null && (
                        <Grid item xs={12} md={3} lg={4} key={index}>
                          <Recommendations
                            item={item}
                            match={index + 1}
                            onChildClick={handleSelectItem}
                            selectedItems={selectedItems}
                            onDislikeClick={handleDislikeItem}
                            dislikedItems={dislikedItems}
                            resetItems={resetItems}
                          />
                        </Grid>
                      )
                  )}
              </Grid>
            </>
          </div>
          {!moreResultRequest && (
            <div className="more_result_div">
              <span className="powered">{t('powered_by')}</span>
              <br />
              <span className="more_result" onClick={moreResult}>
                {t("get_more_result")}
              </span>
            </div>
          )}
          <UserFeedback
            ans={ques}
            selectedDogs={selectedItems}
            dislikedItems={dislikedItems}
            onClickHandler={handleChildClick}
            refresh={refreshResults}
            resetSelected={resetSelectedItems}
          />
          <Footer />
        </>
      )}
    </>
  );
};

export default QnA;
